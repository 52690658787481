@import "styles/variables";

.sidebar {
  color: map-get($colors, "text-secondary");
  min-height: 100vh;
  height: 100%;

  &__logo {
    height: 56px;
    border-bottom: 1.5px solid map-get($colors, "border");
    color: map-get($colors, "text-primary");
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    height: 64px;

    @include media("sm") {
      height: 64px;
    }

    @include media("md") {
      padding: 0 16px 0 28px;
      height: 68px;
    }

    @include media("lg") {
      padding: 0 16px 0 36px;
      height: 72px;
    }

    @include media("xl") {
      height: 84px;
    }

    @include media("xxl") {
      height: 92px;
    }

    @include media("xxxl") {
      height: 96px;
    }

    @include media("fhd") {
      height: 114px;
    }

    @include media("qhd") {
      height: 120px;
    }
  }

  &__menu {
    color: map-get($colors, "text-secondary");
    padding: 48px 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    padding: 0 32px 24px;
    color: map-get($colors, "text-secondary");

    @include media("md") {
      padding: 0 36px 28px;
    }

    @include media("lg") {
      padding: 0 44px 32px;
    }
  }

  &__menu-item {
    padding: 0 24px;
    min-height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    gap: 12px;
    cursor: pointer;
    position: relative;
    font-weight: 400;
    transition: all 0.3s ease;
    will-change: color, font-weight, background-color;
    text-decoration: none;

    @include media("md") {
      padding: 0 28px;
      min-height: 56px;
      font-size: 14px;
    }

    @include media("lg") {
      padding: 0 32px;
      min-height: 64px;
      font-size: 15px;
      gap: 16px;
    }

    @include media("xl") {
      padding: 0 36px;
      min-height: 72px;
    }

    @include media("xxxl") {
      min-height: 80px;
      font-size: 16px;
    }

    &:hover {
      background-color: transparency(map-get($colors, "bg-secondary"), 60);
    }

    &.active {
      color: map-get($colors, "text-primary");
      background-color: map-get($colors, "bg-secondary");
      font-weight: 700;

      svg {
        path {
          stroke: map-get($colors, "text-primary");
        }
        circle {
          stroke: map-get($colors, "text-primary");
        }
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &s {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    &--badge {
      position: absolute;
      right: 12x;
      bottom: 10px;
      font-size: 12px;
      font-weight: 300;
      color: map-get($colors, "text-secondary");
      display: none;

      @include media("lg") {
        display: block;
        right: 16px;
        bottom: 12px;
      }
    }

    &--lock {
      width: 36px;
      height: 36px;
      padding: 10px;
      border-radius: 100px;
      background-color: transparency(map-get($colors, "success"), 90);
      @include center;
    }

    &--icon {
      width: 24px;
      height: 24px;
      @include center;

      svg {
        width: 100%;
        height: 100%;
      }

      @include media("md") {
        width: 28px;
        height: 28px;
      }

      @include media("lg") {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__divider {
    margin: 8px 35px 32px;
    background-color: transparent;
    border: none;
    border-top: 1px solid map-get($colors, "border");
  }
}
