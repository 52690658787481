@import "styles/variables";

.wallet {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding: 14px 16px 12px;

    @include media("md") {
      padding: 16px 20px 14px;
    }

    @include media("lg") {
      flex-direction: row;
      align-items: flex-end;
      padding: 20px 24px 18px;
    }

    @include media("xl") {
      padding: 24px 32px 20px;
    }

    @include media("xxl") {
      padding: 32px 44px 30px;
    }

    @include media("xxxl") {
      padding: 36px 52px 32px;
    }

    @include media("fhd") {
      padding: 40px 60px 36px;
    }

    @include media("qhd") {
      padding: 44px 68px 40px;
    }

    &-left {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 6px;

      @include media("sm") {
        gap: 7px;
      }

      @include media("md") {
        gap: 8px;
      }

      @include media("lg") {
        gap: 9px;
      }

      @include media("xl") {
        gap: 10px;
      }

      @include media("xxxl") {
        gap: 11px;
      }

      @include media("fhd") {
        gap: 12px;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;

      @include media("md") {
        flex-direction: row;
        align-items: center;

        button {
          min-width: 200px;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
