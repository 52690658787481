@import "styles/_variables.scss";

.select-box {
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 16px;

  .select {
    position: relative;
    display: inline-block;
    border-right: 1px solid rgba(255, 255, 255, 0.1);

    &:focus-within,
    &:hover {
      .select-content {
        display: block;
      }

      .arrow {
        rotate: 180deg;
      }
    }

    .select-btn {
      width: 100%;
      font-size: 14px;
      height: 28px;
      width: 120px;
      color: #fff;
      line-height: 20px;
      border-radius: 0.25rem;
      font-weight: 500;
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;

      gap: 12px;
      img {
        width: 28px;
        height: 28px;
      }
    }
    .select-content {
      display: none;
      position: absolute;
      background-color: #fff;
      min-width: 160px;
      z-index: 1;
      height: 200px;
      overflow: scroll;
    }
    .select-value {
      @include center;
      gap: 8px;
    }

    .select-item {
      color: map-get($map: $colors, $key: "bg");
      padding: 12px;
      font-size: 14px;

      img {
        width: 28px;
        height: 28px;
      }
      &:hover {
        background-color: map-get($map: $colors, $key: "text-secondary");
      }
    }
  }

  input {
    height: 28px;
    outline: none;
    width: 100%;
    background-color: transparent;
    color: map-get($map: $colors, $key: "text-primary");
    border: none;
    ::placeholder {
      color: rgba(255, 255, 255, 0.1);
    }
  }
  button {
    color: map-get($map: $colors, $key: "primary");
    font-weight: 500;
  }
}
