@import "styles/variables.scss";

.leaderboard {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    padding: 14px 16px 12px;

    @include media("md") {
      padding: 16px 20px 14px;
    }

    @include media("lg") {
      flex-direction: row;
      align-items: flex-end;
      padding: 20px 24px 18px;
    }

    @include media("xl") {
      padding: 24px 32px 20px;
    }

    @include media("xxl") {
      padding: 32px 44px 30px;
    }

    @include media("xxxl") {
      padding: 36px 52px 32px;
    }

    @include media("fhd") {
      padding: 40px 60px 36px;
    }

    @include media("qhd") {
      padding: 44px 68px 40px;
    }

    &-left {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 6px;

      @include media("sm") {
        gap: 7px;
      }

      @include media("md") {
        gap: 8px;
      }

      @include media("lg") {
        gap: 9px;
      }

      @include media("xl") {
        gap: 10px;
      }

      @include media("xxxl") {
        gap: 11px;
      }

      @include media("fhd") {
        gap: 12px;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 6px;
      width: 100%;

      @include media("md") {
        max-width: 370px;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

.leaderboard-table {
  &__profile {
    display: flex;
    align-items: center;
    gap: 8px;

    @include media("xl") {
      gap: 12px;
    }
  }

  &__rank {
    font-size: 10px;
    font-weight: 600;
    color: transparency(map-get($colors, "text-primary"), 60);

    @include media("md") {
      font-size: 12px;
    }
  }
}
