@import "styles/_variables.scss";

.withdrawal {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 14px 16px 12px;
    gap: 6px;

    @include media("md") {
      padding: 16px 20px 14px;
      gap: 7px;
    }

    @include media("lg") {
      padding: 20px 24px 18px;
      gap: 8px;
    }

    @include media("xl") {
      padding: 24px 32px 20px;
      gap: 9px;
    }

    @include media("xxl") {
      padding: 32px 44px 30px;
      gap: 10px;
    }

    @include media("xxxl") {
      padding: 36px 52px 32px;
      gap: 11px;
    }

    @include media("fhd") {
      padding: 40px 60px 36px;
      gap: 12px;
    }

    @include media("qhd") {
      padding: 44px 68px 40px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    border-top: 1px solid map-get($colors, "border");

    @include media("lg") {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media("xxl") {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
