@import "styles/variables";
.project-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  .project-name {
    font-size: 16px;
    font-weight: 700;
    color: map-get($map: $colors, $key: "text-primary");
  }
  .detail {
    color: #5b636f;
    font-size: 14px;
  }
}
