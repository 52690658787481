@import "styles/_variables.scss";

.profile-form {
  &__wrapper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;

    @include media("md") {
      padding: 20px 16px;
    }

    @include media("lg") {
      border-bottom: none;
      border-right: 1px solid map-get($map: $colors, $key: "border");
      padding: 24px 20px;
      gap: 24px;
    }

    @include media("xxl") {
      padding: 28px 24px;
      gap: 32px;
    }

    @include media("xxxl") {
      padding: 32px;
    }

    @include media("fhd") {
      padding: 32px 40px;
    }
  }

  &__title {
    color: map-get($map: $colors, $key: "text-primary");
    font-size: 16px;
    font-weight: 600;

    @include media("xl") {
      font-size: 18px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;

    @include media("lg") {
      gap: 24px;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
    column-gap: 8px;

    @include media("lg") {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__logout {
    border-top: 1px solid map-get($map: $colors, $key: "border");
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    min-height: 82px;

    @include media("lg") {
      min-height: 114px;
    }

    & button {
      max-width: 292px;
      min-height: 82px;

      @include media("lg") {
        min-height: 114px;
      }
    }
  }
}
