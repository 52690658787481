@import "styles/variables";

.buy {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 14px 16px 12px;
    border-bottom: 1px solid map-get($colors, "border");

    @include media("md") {
      padding: 16px 20px 14px;
    }

    @include media("lg") {
      padding: 20px 24px 18px;
    }

    @include media("xl") {
      padding: 24px 32px 20px;
    }

    @include media("xxl") {
      padding: 32px 44px 30px;
    }

    @include media("xxxl") {
      padding: 36px 52px 32px;
    }

    @include media("fhd") {
      padding: 40px 60px 36px;
    }

    @include media("qhd") {
      padding: 44px 68px 40px;
    }

    &-left {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 6px;

      @include media("sm") {
        gap: 7px;
      }

      @include media("md") {
        gap: 8px;
      }

      @include media("lg") {
        gap: 9px;
      }

      @include media("xl") {
        gap: 10px;
      }

      @include media("xxxl") {
        gap: 11px;
      }

      @include media("fhd") {
        gap: 12px;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 0px 1fr 0px;
    flex-grow: 1;
    padding: 0.5rem;

    &-inner {
      grid-column: 2;
      border-left: 1px solid map-get($colors, "border");
      border-right: 1px solid map-get($colors, "border");
    }

    @include media("md") {
      grid-template-columns: 48px 1fr 48px;
    }

    @include media("lg") {
      grid-template-columns: 64px 1fr 64px;
    }

    @include media("xl") {
      grid-template-columns: 80px 1fr 80px;
    }

    @include media("xxl") {
      grid-template-columns: 112px 1fr 112px;
    }

    @include media("xxxl") {
      grid-template-columns: 160px 1fr 160px;
    }

    @include media("fhd") {
      grid-template-columns: 192px 1fr 192px;
    }

    @include media("qhd") {
      grid-template-columns: 256px 1fr 256px;
    }

    #moonpay-react-sdk-embedded-frame {
      width: 100% !important;
      height: 710px !important;
      border: unset !important;
      margin: unset !important;
      border-radius: 0 !important;

      iframe {
        border: unset !important;
      }
    }
  }
}
