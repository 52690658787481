@import "styles/_variables.scss";

.main-content {
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: 56px 0 0;

  @include media("sm") {
    padding: 64px 0 0;
  }

  @include media("md") {
    padding: 68px 0 0;
  }

  @include media("lg") {
    padding: 72px 168px 0;
  }

  @include media("xl") {
    padding: 84px 190px 0;
  }

  @include media("xxl") {
    padding: 92px 190px 0;
  }

  @include media("xxxl") {
    padding: 96px 220px 0;
  }

  @include media("fhd") {
    padding: 114px 247px 0;
  }

  @include media("qhd") {
    padding: 120px 275px 0;
  }

  &__inner {
    max-width: 100%;
    width: 100%;
    flex: 1;
    position: relative;

    @include media("md") {
      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 1.5px;
        height: 100%;
        min-height: 100vh;
        background-color: map-get($colors, "border");
      }

      &::before {
        left: -1.5px;
        top: 0;
      }

      &::after {
        right: -1.5px;
        top: 0;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__link {
    font-size: 12px;
    font-weight: 500;
    text-wrap: nowrap;
    line-height: 1.5;
    color: map-get($colors, "primary");

    @include media("lg") {
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 24px;
  }

  &__icon {
    width: 100%;
    height: 100%;
    max-width: 210px;
    max-height: 130px;
    margin-bottom: 6px;

    @include media("md") {
      max-width: 260px;
      max-height: 180px;
      margin-bottom: 12px;
    }

    @include media("lg") {
      max-width: 284px;
      max-height: 204px;
      margin-bottom: 24px;
    }

    &-file {
      @include full;
      object-fit: cover;
      object-position: top;
    }
  }

  &__title {
    font-size: 24px;
    letter-spacing: -3%;
    line-height: 1.2;
    font-weight: 600;
    color: map-get($map: $colors, $key: "text-primary");
    text-align: center;

    @include media("md") {
      font-size: 32px;
      line-height: 1.5;
    }
  }

  &__text {
    color: transparency(map-get($colors, "text-primary"), 40);
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    line-height: 1.3;

    @include media("md") {
      max-width: 50%;
      font-size: 12px;
      line-height: 1.5;
    }

    & > .highlighted {
      color: map-get($map: $colors, $key: "primary");
    }

    &.text-normal {
      text-align: left;
    }

    @include media("lg") {
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 32px;
    padding: 0 16px;

    @include media("sm") {
      padding: 0 24px;
    }

    @include media("md") {
      padding: 0 32px;
    }

    @include media("lg") {
      padding-top: 24px;
    }
  }

  &__form {
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 578px;
    width: 100%;
    align-self: center;
    padding: 0 16px;

    &-row {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 16px;
      width: 100%;

      @include media("lg") {
        gap: 8px;
        flex-direction: row;
      }
    }

    @include media("sm") {
      padding: 0 24px;
    }

    @include media("md") {
      gap: 24px;
      padding: 0 32px;
    }
  }

  &__footer {
    min-height: 82px;
    border-top: 1.5px solid map-get($colors, "border");
    display: grid;
    align-items: stretch;
    justify-content: center;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr;

    @include media("md") {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      align-items: center;
      justify-content: space-between;
    }

    &-content {
      padding: 14px 16px;

      @include media("md") {
        padding: 12px 32px;
      }
    }
  }

  &__button {
    height: 100%;
    padding: 0;
  }
}
