@import "styles/variables";

.performance {
  display: flex;
  align-items: stretch;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 14px 16px 12px;

    @include media("md") {
      padding: 16px 20px 14px;
    }

    @include media("lg") {
      padding: 20px 24px 18px;
    }

    @include media("xl") {
      padding: 24px 32px 20px;
    }

    @include media("xxl") {
      padding: 32px 44px 30px;
    }

    @include media("xxxl") {
      padding: 36px 52px 32px;
    }

    @include media("fhd") {
      padding: 40px 60px 36px;
    }

    @include media("qhd") {
      padding: 44px 68px 40px;
    }

    &-left {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 6px;

      @include media("sm") {
        gap: 7px;
      }

      @include media("md") {
        gap: 8px;
      }

      @include media("lg") {
        gap: 9px;
      }

      @include media("xl") {
        gap: 10px;
      }

      @include media("xxxl") {
        gap: 11px;
      }

      @include media("fhd") {
        gap: 12px;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 16px;

    @include media("md") {
      padding: 14px 20px;
    }

    @include media("lg") {
      padding: 16px 24px;
      flex-direction: row;
      align-items: center;
    }

    @include media("xl") {
      padding: 20px 32px;
    }

    @include media("xxl") {
      padding: 24px 44px;
    }

    @include media("qhd") {
      padding: 32px 56px;
    }
  }

  &__profit {
    color: transparency(map-get($colors, "text-primary"), 60);
    font-weight: 600;
    line-height: normal;
    font-size: 12px;

    @include media("md") {
      font-size: 13px;
    }

    @include media("lg") {
      font-size: 14px;
    }

    @include media("xl") {
      font-size: 15px;
    }

    @include media("xxl") {
      font-size: 16px;
    }

    span {
      color: map-get($colors, "primary");
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;

    @include media("md") {
      flex-direction: row;

      button {
        min-width: 200px;
      }
    }
  }
}
