@import "styles/variables";

.subtitle {
  @include center;
  gap: 4px;
  color: map-get($map: $colors, $key: "text-secondary");
  justify-content: flex-start;

  @include media("lg") {
    gap: 6px;
  }

  @include media("xxxl") {
    gap: 8px;
  }

  &__text {
    font-weight: 600;
    line-height: 1;
    font-size: 10px;

    @include media("md") {
      font-size: 12px;
    }

    @include media("xl") {
      font-size: 14px;
    }

    @include media("xxxl") {
      font-size: 16px;
    }
  }

  &__icon {
    @include center;
    width: 16px;
    height: 16px;

    @include media("md") {
      width: 18px;
      height: 18px;
    }

    @include media("lg") {
      width: 20px;
      height: 20px;
    }

    @include media("xl") {
      width: 22px;
      height: 22px;
    }

    @include media("xxxl") {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: map-get($map: $colors, $key: "text-secondary");
      }
    }
  }
}
