@import "styles/variables";

.dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__header {
    padding: 6px 8px;
    border: none;
    cursor: pointer;
    gap: 4px;
    border: 1px solid transparent;
    border-bottom: none;
    transition: all 0.3s ease;
    @include center;

    @include media("sm") {
      padding: 8px 10px;
      gap: 5px;
    }

    @include media("md") {
      gap: 6px;
      padding: 10px 12px;
    }

    @include media("lg") {
      gap: 7px;
      padding: 11px 14px;
    }

    @include media("xl") {
      gap: 8px;
      padding: 12px 16px;
    }

    @include media("xxxl") {
      padding: 14px 18px;
    }

    @include media("qhd") {
      gap: 10px;
    }

    &-icon {
      width: 8px;
      height: 8px;

      @include media("sm") {
        width: 10px;
        height: 10px;
      }

      @include media("md") {
        width: 12px;
        height: 12px;
      }

      @include media("lg") {
        width: 14px;
        height: 14px;
      }

      @include media("xl") {
        width: 16px;
        height: 16px;
      }
    }

    &-text {
      font-size: 11px;
      font-weight: 600;
      color: map-get($map: $colors, $key: "text-primary");
      transition: color 0.3s ease;

      @include media("sm") {
        font-size: 12px;
      }

      @include media("md") {
        font-size: 13px;
      }

      @include media("lg") {
        font-size: 14px;
      }

      @include media("xl") {
        font-size: 15px;
      }

      @include media("xxxl") {
        font-size: 16px;
      }
    }
  }

  &__arrow {
    width: 12px;
    height: 12px;
    transition: all 0.3s ease;
    @include center;

    @include media("md") {
      width: 14px;
      height: 14px;
    }

    @include media("lg") {
      width: 16px;
      height: 16px;
    }

    @include media("xl") {
      width: 18px;
      height: 18px;
    }

    @include media("xxl") {
      width: 20px;
      height: 20px;
    }

    @include media("xxxl") {
      width: 22px;
      height: 22px;
    }

    @include media("fhd") {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 65%;
      height: 65%;

      @include media("xl") {
        width: 45%;
        height: 45%;
      }
    }
  }

  &__content {
    display: none;
    position: absolute;
    background-color: map-get($map: $colors, $key: "bg");
    border: 1px solid map-get($map: $colors, $key: "border");
    z-index: 3;
    top: 100%;
    left: 0;
    right: 0;
    min-width: max-content;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    padding: 4px 0;
    gap: 2px;

    button {
      width: 100%;
      color: map-get($map: $colors, $key: "text-primary");
      padding: 12px 16px;
      text-decoration: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.4;
      }
    }
  }

  &:hover &__content {
    display: flex;
  }

  &:hover &__header {
    border-color: map-get($map: $colors, $key: "border");
  }

  &:hover &__header-text {
    color: transparency(map-get($map: $colors, $key: "text-primary"), 40);
  }

  &:hover &__arrow {
    transform: rotate(180deg);
    opacity: 0.6;
  }
}
