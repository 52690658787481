@import "styles/_variables.scss";

.custom-select {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__label {
    font-weight: 500;
    color: map-get($map: $colors, $key: "text-primary");
    margin-bottom: 8px;
    padding-left: 14px;
    font-size: 10px;

    @include media("sm") {
      font-size: 11px;
    }

    @include media("md") {
      font-size: 12px;
    }

    @include media("lg") {
      font-size: 13px;
      margin-bottom: 12px;
    }

    @include media("xl") {
      font-size: 14px;
    }

    @include media("xxl") {
      font-size: 15px;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 300px;
  }

  &__header {
    padding: 8px;
    border: none;
    cursor: pointer;
    flex: 1;
    border-radius: 8px;
    gap: 10px;
    border: 1px solid map-get($map: $colors, $key: "border");
    display: flex;
    align-items: center;
    height: 52px;

    @include media("sm") {
      padding: 10px;
      gap: 11px;
    }

    @include media("md") {
      gap: 12px;
      padding: 12px;
      height: 56px;
    }

    @include media("lg") {
      gap: 14px;
      height: 60px;
      padding: 14px;
    }

    @include media("xl") {
      gap: 16px;
      height: 64px;
      padding: 16px;
    }

    @include media("xxl") {
      height: 72px;
    }

    @include media("xxxl") {
      padding: 18px;
    }

    @include media("qhd") {
      gap: 20px;
    }
  }

  &__headerWithError {
    border-color: map-get($map: $colors, $key: "secondary");
  }

  &__icon {
    width: 12px;
    height: 12px;
    transition: opacity 0.3s ease;

    @include media("sm") {
      width: 14px;
      height: 14px;
    }

    @include media("md") {
      width: 18px;
      height: 18px;
    }

    @include media("lg") {
      width: 20px;
      height: 20px;
    }

    @include media("xl") {
      width: 22px;
      height: 22px;
    }

    @include media("xxl") {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    font-size: 11px;
    color: map-get($map: $colors, $key: "text-primary");
    transition: color 0.3s ease;
    flex-grow: 1;

    @include media("sm") {
      font-size: 12px;
    }

    @include media("md") {
      font-size: 13px;
    }

    @include media("lg") {
      font-size: 14px;
    }

    @include media("xl") {
      font-size: 15px;
    }

    @include media("xxxl") {
      font-size: 16px;
    }
  }

  &__arrow {
    width: 12px;
    height: 12px;
    transition: all 0.3s ease;
    @include center;

    @include media("md") {
      width: 14px;
      height: 14px;
    }

    @include media("lg") {
      width: 16px;
      height: 16px;
    }

    @include media("xl") {
      width: 18px;
      height: 18px;
    }

    @include media("xxl") {
      width: 20px;
      height: 20px;
    }

    @include media("xxxl") {
      width: 22px;
      height: 22px;
    }

    @include media("fhd") {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 65%;
      height: 65%;

      @include media("xl") {
        width: 45%;
        height: 45%;
      }
    }
  }

  &__list {
    display: none;
    position: absolute;
    background-color: map-get($map: $colors, $key: "bg");
    border: 1px solid map-get($map: $colors, $key: "border");
    z-index: 3;
    top: 100%;
    left: 0;
    right: 0;
    min-width: max-content;
    width: 100%;
    max-height: 25vh;
    overflow: auto;
    flex-direction: column;
    align-items: stretch;
    padding: 4px 0;
    gap: 2px;
  }

  &__item {
    width: 100%;
    color: map-get($map: $colors, $key: "text-primary");
    padding: 12px 16px;
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    transition: all 0.3s ease;
    cursor: pointer;

    &-icon {
      width: 10px;
      height: 10px;
      opacity: 0.5;
      transition: opacity 0.3s ease;

      @include media("sm") {
        width: 12px;
        height: 12px;
      }

      @include media("md") {
        width: 16px;
        height: 16px;
      }

      @include media("lg") {
        width: 18px;
        height: 18px;
      }

      @include media("xl") {
        width: 20px;
        height: 20px;
      }

      @include media("xxl") {
        width: 22px;
        height: 22px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-text {
      font-size: 10px;
      font-weight: 500;
      color: transparency(map-get($map: $colors, $key: "text-primary"), 20);
      transition: color 0.3s ease;

      @include media("md") {
        font-size: 12px;
      }

      @include media("lg") {
        font-size: 13px;
      }

      @include media("xxl") {
        font-size: 14px;
      }
    }

    &:hover &-text {
      color: map-get($map: $colors, $key: "text-primary");
    }

    &:hover &-icon {
      opacity: 1;
    }
  }

  &__wrapper--active &__list {
    display: flex;
  }

  &__wrapper--active &__header {
    border-bottom-color: transparent;
  }

  &__wrapper--active &__text {
    color: transparency(map-get($map: $colors, $key: "text-primary"), 40);
  }

  &__wrapper--active &__icon {
    opacity: 0.6;
  }

  &__wrapper--active &__arrow {
    transform: rotate(180deg);
    opacity: 0.6;
  }
}
