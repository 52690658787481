@import "styles/variables";

.button {
  font-weight: 500;
  padding: 28px;
  width: 100%;
  position: relative;
  height: 100%;
  text-wrap: nowrap;
  @include center;
  gap: 6px;

  @include media("xl") {
    gap: 12px;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
  }

  &__icon {
    @include center;
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;

    @include media("lg") {
      font-size: 16px;
    }
  }

  &--badge {
    font-size: 12px;
    font-weight: 500;
    opacity: 0.3;
    position: absolute;
    left: 12px;
    bottom: 12px;
  }

  &.btn-primary {
    color: #0b0c0d;
    background-color: map-get($map: $colors, $key: "primary");
    svg {
      path {
        stroke: #0b0c0d;
      }
    }
    &:hover {
      opacity: 0.3;
    }
  }

  &.btn-secondary {
    background-color: #1c1b1f;
    color: map-get($map: $colors, $key: "text-primary");
    svg {
      path {
        stroke: map-get($map: $colors, $key: "text-primary");
      }
    }
    &:hover {
      color: #0b0c0d;
      background-color: map-get($map: $colors, $key: "primary");
      svg {
        path {
          stroke: #0b0c0d;
        }
      }
    }
  }

  &.btn-info {
    color: #0b0c0d;
    background-color: map-get($map: $colors, $key: "secondary");
    svg {
      path {
        stroke: #0b0c0d;
      }
    }

    &:hover {
      opacity: 0.3;
    }
  }

  &.btn-warning {
    color: #0b0c0d;
    background-color: map-get($map: $colors, $key: "warning");
    svg {
      path {
        stroke: #0b0c0d;
      }
    }

    &:hover {
      opacity: 0.3;
    }
  }

  &.btn-primary-inverted {
    color: map-get($map: $colors, $key: "primary");
    background-color: #0b0c0d;
    svg {
      path {
        stroke: #0b0c0d;
      }
    }

    .button--badge {
      color: #f6f6f6;
    }

    &:hover {
      color: #0b0c0d;
      background-color: map-get($map: $colors, $key: "primary");

      .button--badge {
        color: #0b0c0d;
      }
    }
  }
}
