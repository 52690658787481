@import "styles/variables";

.amount {
  @include center;
  gap: 20px;
  justify-content: flex-start;

  &__icon {
    @include center;
    width: 28px;
    height: 28px;

    @include media("sm") {
      width: 24px;
      height: 24px;
    }

    @include media("md") {
      width: 28px;
      height: 28px;
    }

    @include media("lg") {
      width: 30px;
      height: 30px;
    }

    @include media("xl") {
      width: 32px;
      height: 32px;
    }

    @include media("xxl") {
      width: 34px;
      height: 34px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__value {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.3;
    color: map-get($colors, "text-primary");

    @include media("sm") {
      font-size: 13px;
    }

    @include media("md") {
      font-size: 14px;
    }

    @include media("lg") {
      font-size: 15px;
    }

    @include media("xxl") {
      font-size: 16px;
    }

    @include media("fhd") {
      font-size: 17px;
    }

    @include media("qhd") {
      font-size: 18px;
    }
  }
}
