@import "styles/_variables.scss";
.percent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  &.increase {
    color: map-get($map: $colors, $key: "primary");
    svg {
      margin-bottom: 4px;
      path {
        stroke: map-get($map: $colors, $key: "primary");
      }
    }
  }
  &.decrease {
    color: map-get($map: $colors, $key: "secondary");
    svg {
      path {
        stroke: map-get($map: $colors, $key: "secondary");
      }
    }
  }
}
