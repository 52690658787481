@import "styles/variables.scss";

.otp {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 8px;

  @include media("md") {
    gap: 12px;
  }

  &__label {
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    color: map-get($colors, "text-primary");
    margin-left: 8px;

    @include media("md") {
      font-size: 12px;
      margin-left: 12px;
    }

    @include media("lg") {
      font-size: 14px;
      margin-left: 16x;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    gap: 4px;

    @include media("md") {
      gap: 8px;
    }
  }

  &__input-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    border-radius: 6px;
    border: 1.5px solid map-get($colors, "border");
    background-color: map-get($colors, "bg");
    color: map-get($colors, "text-primary");
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    outline: 0;
    width: 100%;

    &::placeholder {
      color: transparency(map-get($colors, "text-primary"), 80);
      font-weight: 300;
    }

    &:focus-within {
      outline: none;
    }

    @include media("md") {
      height: 64px;
    }

    @include media("xl") {
      height: 72px;
    }
  }
}
