@import "styles/variables";

.trade-row {
  grid-template-columns: 1fr;

  @include media("md") {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media("lg") {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media("xl") {
    grid-template-columns: 4fr repeat(2, 2fr) repeat(3, 1fr);
  }
}

.trade-extra-row {
  grid-template-columns: 1fr;

  @include media("md") {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media("lg") {
    grid-template-columns: repeat(4, 1fr);
  }
}

.trade-project {
  @include center;
  gap: 12px;
  justify-content: flex-start;

  &__images {
    @include center;

    img {
      width: 38px;
      height: 38px;
      border-radius: 100%;
      border: 3px solid map-get($map: $colors, $key: "bg");
      &:last-child {
        margin-left: -12px;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__token {
    color: map-get($map: $colors, $key: "text-primary");
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__status {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 4px 8px;
    background-color: transparency(map-get($map: $colors, $key: "primary"), 85);
    color: map-get($map: $colors, $key: "primary");
    opacity: 0.6;
  }

  &__date {
    color: map-get($map: $colors, $key: "text-secondary");
    font-weight: 500;
    font-size: 14px;
    color: map-get($map: $colors, $key: "text-secondary");
  }
}
