@import "styles/_variables.scss";

.avatar {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid map-get($map: $colors, $key: "border");

  @include media("md") {
    padding: 20px 16px;
  }

  @include media("lg") {
    padding: 24px 20px;
    gap: 24px;
  }

  @include media("xxl") {
    padding: 28px 24px;
    gap: 32px;
  }

  @include media("xxxl") {
    padding: 32px;
  }

  @include media("fhd") {
    padding: 32px 40px;
  }

  &__title {
    color: map-get($map: $colors, $key: "text-primary");
    font-size: 16px;
    font-weight: 600;

    @include media("xl") {
      font-size: 18px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 16px;

    @include media("xl") {
      gap: 20px;
    }
  }

  &__image {
    width: 66px;
    height: 66px;
    border-radius: 500px;
    border: 2px solid transparent;

    @include media("lg") {
      width: 84px;
      height: 84px;
    }

    @include media("xxl") {
      width: 104px;
      height: 104px;
    }

    &-preview {
      width: 64px;
      height: 64px;
      border-radius: 500px;
      border: 4px solid map-get($map: $colors, $key: "bg");

      @include media("lg") {
        width: 80px;
        height: 80px;
      }

      @include media("xxl") {
        width: 100px;
        height: 100px;
        border: 6px solid map-get($map: $colors, $key: "bg");
      }
    }

    &--approved {
      border-color: map-get($map: $colors, $key: "primary");
    }

    &--pending {
      border-color: map-get($map: $colors, $key: "warning");
    }

    &--rejected {
      border-color: map-get($map: $colors, $key: "secondary");
    }
  }

  &__upload {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    flex-grow: 1;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: map-get($map: $colors, $key: "bg-secondary");
    padding: 12px 24px;
    color: map-get($map: $colors, $key: "text-primary");
    font-size: 14px;
    font-weight: 500;
    height: 48px;
    max-width: 260px;
    cursor: pointer;

    @include media("lg") {
      height: 56px;
    }

    @include media("xxl") {
      height: 64px;
    }
  }

  &__desc {
    color: map-get($map: $colors, $key: "text-primary");
    font-size: 12px;
    font-weight: 500;
  }

  &__input {
    display: none;
  }
}

.upload {
  color: map-get($map: $colors, $key: "text-primary");
  border-bottom: 2px solid #171819;

  & > div {
    padding: 38px 43px;
    font-size: 18px;
    font-weight: 600;

    &:last-child {
      display: flex;
      align-items: center;
      gap: 20px;
      .image {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        overflow: hidden;
        img {
          @include full;
        }
      }
      .input-upload {
        font-size: 14px;
        font-weight: 500;

        input {
          display: none;
        }
        label {
          cursor: pointer;
          width: 225px;
          height: 66px;
          border-radius: 2px;
          background-color: #1c1b1f;
          @include center;
          padding: 24px;
          margin-bottom: 8px;
        }
        span {
          font-size: 12px;
        }
      }
    }
  }
}
