@import "styles/variables";

.dnavbar {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  &__profile {
    cursor: pointer;
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0 16px;
    gap: 16px;

    @include media("sm") {
      padding: 0 20px;
      gap: 18px;
    }

    @include media("md") {
      padding: 0 24px;
      gap: 20px;
    }

    @include media("lg") {
      padding: 0 28px;
      gap: 22px;
    }

    @include media("xl") {
      padding: 0 32px;
      gap: 24px;
    }

    @include media("xxl") {
      padding: 0 36px;
      gap: 26px;
    }

    @include media("xxxl") {
      padding: 0 40px;
      gap: 28px;
    }

    @include media("fhd") {
      padding: 0 42px;
    }

    @include media("qhd") {
      padding: 0 48px;
    }
  }

  &__right {
    display: flex;
    align-items: stretch;
    gap: 16px;

    @include media("sm") {
      gap: 20px;
    }

    @include media("md") {
      gap: 24px;
    }

    @include media("lg") {
      gap: 28px;
    }

    @include media("xl") {
      gap: 32px;
    }

    @include media("xxl") {
      gap: 36px;
    }

    @include media("xxxl") {
      gap: 40px;
    }
  }

  &__right-icon {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include media("md") {
      width: 16px;
      height: 16px;
    }

    @include media("lg") {
      width: 20px;
      height: 20px;
    }

    @include media("xl") {
      width: 22px;
      height: 22px;
    }

    @include media("xxxl") {
      width: 24px;
      height: 24px;
    }

    @include media("fhd") {
      width: 28px;
      height: 28px;
    }

    & > svg {
      width: 100%;
      height: 100%;
    }

    &s {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      @include media("sm") {
        gap: 12px;
      }

      @include media("md") {
        gap: 14px;
      }

      @include media("lg") {
        gap: 16px;
      }

      @include media("xl") {
        gap: 18px;
      }

      @include media("xxl") {
        gap: 20px;
      }

      @include media("xxxl") {
        gap: 22px;
      }

      @include media("fhd") {
        gap: 24px;
      }

      @include media("qhd") {
        gap: 26px;
      }
    }
  }

  &__button {
    min-width: 180px;
    display: none;

    @include media("sm") {
      min-width: 190px;
    }

    @include media("md") {
      min-width: 200px;
    }

    @include media("lg") {
      min-width: 220px;
      display: block;
    }

    @include media("xl") {
      min-width: 230px;
    }

    @include media("xxl") {
      min-width: 240px;
    }

    @include media("xxxl") {
      min-width: 250px;
    }

    @include media("fhd") {
      min-width: 270px;
    }

    @include media("qhd") {
      min-width: 300px;
    }
  }

  &__toggle {
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;

    & > span {
      width: 100%;
      height: 1.5px;
      background-color: map-get($colors, "text-secondary");
      transition: all 0.3s ease;
    }

    &:hover {
      & > span {
        background-color: map-get($colors, "text-primary");
      }
    }

    @include media("lg") {
      display: none;
    }
  }
}
