@import "styles/variables";

.dashboard {
  display: flex;

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: stretch;
    transition: transform 0.6s ease;

    @include media("lg") {
      margin-left: 275px;
    }
  }

  &__sidebar {
    width: 260px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background-color: map-get($colors, "bg");
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1.5px solid map-get($colors, "border");
    transform: translateX(-100%);
    transition: transform 0.6s ease;

    &.open {
      transform: translateX(0);

      & + .dashboard__container {
        transform: translateX(260px);
      }
    }

    @include media("lg") {
      transform: translateX(0);
      width: 275px;
    }
  }

  &__content {
    min-height: calc(100vh - 56px);

    @include media("sm") {
      min-height: calc(100vh - 64px);
    }

    @include media("md") {
      min-height: calc(100vh - 68px);
    }

    @include media("lg") {
      min-height: calc(100vh - 72px);
    }

    @include media("xl") {
      min-height: calc(100vh - 84px);
    }

    @include media("xxl") {
      min-height: calc(100vh - 92px);
    }

    @include media("xxxl") {
      min-height: calc(100vh - 96px);
    }

    @include media("fhd") {
      min-height: calc(100vh - 114px);
    }

    @include media("qhd") {
      min-height: calc(100vh - 120px);
    }
  }
}
