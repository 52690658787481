@import "styles/_variables.scss";

.profile-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    border-bottom: 1px solid map-get($map: $colors, $key: "border");

    @include media("lg") {
      flex-direction: row;
    }

    &-item {
      flex-grow: 1;
      padding: 16px;
      border-bottom: 1px solid map-get($map: $colors, $key: "border");
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;

      @include media("md") {
        padding: 20px 16px;
      }

      @include media("lg") {
        border-bottom: none;
        border-right: 1px solid map-get($map: $colors, $key: "border");
        padding: 24px 20px;
      }

      @include media("xxl") {
        padding: 28px 24px;
      }

      @include media("xxxl") {
        padding: 32px;
      }

      @include media("fhd") {
        padding: 32px 40px;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
  }

  &__referral {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    color: map-get($map: $colors, $key: "text-secondary");

    &-code {
      font-weight: 600;
      font-size: 14px;

      @include media("lg") {
        font-size: 16px;
      }
    }

    &-label {
      padding-right: 4px;
    }

    &-value {
      color: map-get($map: $colors, $key: "text-primary");
    }
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr;

    @include media("xxl") {
      grid-template-columns: 2fr 3fr;
    }
  }

  &__left {
    border-bottom: 1px solid map-get($map: $colors, $key: "border");

    @include media("xxl") {
      border-bottom: none;
      border-right: 1px solid map-get($map: $colors, $key: "border");
    }
  }

  &__right {
  }
}
