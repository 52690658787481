@import "styles/_variables.scss";

.modal-content-stake {
  color: map-get($map: $colors, $key: "text-primary");
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 36px;

  .list {
    list-style: none;
    padding-left: 0;

    li {
      position: relative;
      padding-left: 24px;
      padding-bottom: 12px;

      .icon {
        position: absolute;
        left: 0;
        top: 0;
        svg {
          path {
            stroke: map-get($map: $colors, $key: "primary");
          }
        }
      }
    }
  }
  .info {
    background-color: map-get($map: $colors, $key: "bg");
    color: #5b636f;
    padding: 24px;
    padding-left: 48px;
    margin-top: 24px;
    display: flex;
    gap: 16px;
    position: relative;
    svg {
      position: absolute;
      top: 24px;
      left: 8px;
    }
  }
}
