@import "styles/variables.scss";

.home {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__row {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);

    @include media("xl") {
      grid-template-columns: 5fr 4fr;
      grid-template-rows: 1fr;
    }

    &:not(:last-child) {
      border-bottom: 1px solid map-get($colors, "border");
    }
  }

  &__cell {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &:not(:last-child) {
      border-bottom: 1px solid map-get($colors, "border");
    }

    @include media("xl") {
      &:not(:last-child) {
        border-bottom: none;
        border-right: 1px solid map-get($colors, "border");
      }
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 14px 16px 12px;

    @include media("md") {
      padding: 16px 20px 14px;
    }

    @include media("lg") {
      padding: 20px 24px 18px;
    }

    @include media("xl") {
      padding: 24px 32px 20px;
    }

    @include media("xxl") {
      padding: 32px 44px 30px;
    }

    @include media("xxxl") {
      padding: 36px 52px 32px;
    }

    @include media("fhd") {
      padding: 40px 60px 36px;
    }

    @include media("qhd") {
      padding: 44px 68px 40px;
    }

    &-left {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 6px;

      @include media("sm") {
        gap: 7px;
      }

      @include media("md") {
        gap: 8px;
      }

      @include media("lg") {
        gap: 9px;
      }

      @include media("xl") {
        gap: 10px;
      }

      @include media("xxxl") {
        gap: 11px;
      }

      @include media("fhd") {
        gap: 12px;
      }
    }
  }

  &__body {
    display: flex;
    align-items: stretch;
    padding: 0 16px 16px;

    @include media("sm") {
      padding: 0 20px 20px;
    }

    @include media("md") {
      padding: 0 24px 24px;
    }

    @include media("lg") {
      padding: 0 30px 28px;
    }

    @include media("xl") {
      padding: 0 36px 32px;
    }

    @include media("xxl") {
      padding: 0 40px 36px;
    }

    @include media("xxxl") {
      padding: 0 44px 38px;
    }

    .chart__holder {
      width: 100%;
      // min-height: 355px;
      position: relative;
      border: 1px solid map-get($colors, "border");
      border-radius: 8px;
    }
  }
}
