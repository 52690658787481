@import "styles/_variables.scss";
.modal {
  display: none;
  z-index: 1000;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%);
  background-color: #1c1b1f;
  width: 640px;
  border-radius: 4px;
  &.show {
    display: block;
  }

  .modal-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    .modal-title {
      padding: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
    }
    .inner-title {
      @include center;
      justify-content: flex-start;
      gap: 16px;
      // padding: 12px 24px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }
      .main-title {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
      }
      .detail {
        color: rgba(255, 255, 255, 0.4);
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .modal-body {
    // padding: 36px;
  }
}
.backdrop {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  z-index: 100;
  display: none;
  &.show-backdrop {
    display: block;
  }
}
