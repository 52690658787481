@import "styles/_variables.scss";
.modal-content {
  position: relative;

  .content {
    padding: 36px;
  }

  .amount {
    display: flex;
    justify-content: space-between;
    color: map-get($map: $colors, $key: "text-primary");
    font-size: 14px;
    margin-bottom: 16px;
    span {
      color: #5b636f;
    }
  }

  .percents {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    margin-top: 16px;
    cursor: pointer;

    &__item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      @include center;
      padding: 16px;

      &.selected-percent {
        border: 1px solid map-get($map: $colors, $key: "primary");
        color: map-get($map: $colors, $key: "primary");
      }
    }

    &.disabled {
      cursor: not-allowed;

      &__item {
        opacity: 0.5;
      }
    }
  }

  .alert {
    background-color: map-get($map: $colors, $key: "bg");
    margin-bottom: 80px;
    color: #5b636f;
    padding: 24px;
    padding-left: 48px;
    margin-top: 24px;
    display: flex;
    gap: 16px;
    position: relative;
    svg {
      position: absolute;
      top: 24px;
      left: 8px;
    }
  }
  .button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 640px;
    height: 82px;
  }
}
