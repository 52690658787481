@import "styles/variables";

.profile {
  @include center;
  justify-content: flex-start;
  gap: 8px;

  @include media("md") {
    gap: 10px;
  }

  @include media("lg") {
    gap: 12px;
  }

  @include media("xl") {
    gap: 14px;
  }

  @include media("xxxl") {
    gap: 16px;
  }

  &__photo {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    overflow: hidden;

    @include media("md") {
      width: 40px;
      height: 40px;
    }

    @include media("lg") {
      width: 44px;
      height: 44px;
    }

    @include media("xl") {
      width: 48px;
      height: 48px;
    }

    @include media("xxxl") {
      width: 52px;
      height: 52px;
    }

    @include media("fhd") {
      width: 56px;
      height: 56px;
    }

    &--approved {
      border: solid 2px map-get($map: $colors, $key: "primary");
    }
    &--rejected {
      border: solid 2px map-get($map: $colors, $key: "secondary");
    }
    &--pending,
    &--reviewing {
      border: solid 2px map-get($map: $colors, $key: "warning");
    }
    img {
      @include full;
      border-radius: 100%;
      border: solid 3px map-get($map: $colors, $key: "bg");
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2px;

    @include media("md") {
      gap: 4px;
    }

    @include media("xl") {
      gap: 6px;
    }

    @include media("fhd") {
      gap: 8px;
    }

    &-name {
      font-size: 10px;
      line-height: 1;
      font-weight: 600;
      color: map-get($map: $colors, $key: "text-primary");

      @include media("md") {
        font-size: 12px;
      }

      @include media("xl") {
        font-size: 14px;
      }

      @include media("xxxl") {
        font-size: 16px;
      }
    }

    &-type {
      @include center;
      justify-content: flex-start;
      gap: 6px;
      font-size: 8px;
      text-transform: uppercase;
      line-height: 1;
      font-weight: 600;

      &::before {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 100%;
      }

      @include media("md") {
        font-size: 10px;
      }

      @include media("xl") {
        font-size: 12px;
      }

      @include media("xxxl") {
        font-size: 14px;
      }

      &--approved {
        color: map-get($map: $colors, $key: "primary");
        &::before {
          background-color: map-get($map: $colors, $key: "primary");
        }
      }
      &--rejected {
        color: map-get($map: $colors, $key: "secondary");
        &::before {
          background-color: map-get($map: $colors, $key: "secondary");
        }
      }
      &--pending,
      &--reviewing {
        color: map-get($map: $colors, $key: "warning");
        &::before {
          background-color: map-get($map: $colors, $key: "warning");
        }
      }
    }
  }
}
