@import "styles/variables";

.trade-tabs {
  color: map-get($colors, "text-secondary");
  width: 100%;
  @include center;
  justify-content: flex-start;
  min-height: 58px;
  border-bottom: 1px solid map-get($colors, "border");
  padding-left: 16px;
  padding-right: 16px;

  @include media("md") {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include media("lg") {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include media("xl") {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include media("xxl") {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include media("xxxl") {
    padding-left: 48px;
    padding-right: 48px;
  }

  @include media("fhd") {
    padding-left: 53px;
    padding-right: 53px;
  }

  @include media("qhd") {
    padding-left: 64px;
    padding-right: 64px;
  }

  .tab-item {
    padding: 16px 0;
    text-align: left;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;

    &:not(:first-child) {
      margin-left: 32px;

      @include media("lg") {
        margin-left: 40px;
      }

      @include media("xxl") {
        margin-left: 48px;
      }
    }

    &.active {
      color: map-get($colors, "text-primary");
      border-bottom: 2px solid map-get($colors, "text-primary");
    }
  }
}
