html,
body {
  padding: 0;
  margin: 0;
  // scroll-behavior: smooth !important;
  max-width: 100vw;
  overflow-x: hidden;
}

* {
  font-family: "Chakra Petch", sans-serif;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  font-family: "Chakra Petch", sans-serif;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none; /* Safari and Chrome */
  }
}

body {
  overflow: hidden;
  background-color: map-get($colors, "bg");
  color: map-get($colors, "text-primary");
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
button {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
}

button,
span,
input,
p,
a,
h1,
h2,
h3,
h4,
h5 {
  font-family: inherit;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
