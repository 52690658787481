@import "styles/variables.scss";

.info {
  padding: 24px;
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  gap: 39px;
  z-index: 1;

  .availableBalance {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__subtitle {
      font-size: 12px;
      color: map-get($map: $colors, $key: "text-secondary");
    }

    .title {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 24px;
      font-weight: 600;

      &__badge {
        color: map-get($map: $colors, $key: "text-secondary");
        font-size: 24px;
        font-weight: 600;
        position: unset;
      }
    }
  }

  .investedBalance {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__subtitle {
      font-size: 12px;
      color: map-get($map: $colors, $key: "text-secondary");
    }

    .title {
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: map-get($map: $colors, $key: "primary");

      &__badge {
        color: map-get($map: $colors, $key: "text-primary");
        font-size: 14px;
        font-weight: 400;
        position: unset;
        margin-left: 4px;
      }

      svg {
        width: 20px;
        height: 20px;

        path {
          stroke: map-get($map: $colors, $key: "primary");
        }
      }
    }
  }
}

.chart {
  border-radius: 8px;
}

.VictoryContainer {
  border: unset;
  display: flex;
  flex-direction: column;

  svg {
    border-radius: 7px;
  }
}

.VictoryContainer svg g[role*="presentation"] {
  display: none;
}
