@import "styles/_variables.scss";

.stake {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  min-height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid map-get($map: $colors, $key: "border");
  }

  &__header,
  &__plans {
    padding: 14px 16px 12px;

    @include media("md") {
      padding: 16px 20px 14px;
    }

    @include media("lg") {
      padding: 20px 24px 18px;
    }

    @include media("xl") {
      padding: 24px 32px 20px;
    }

    @include media("xxl") {
      padding: 32px 44px 30px;
    }

    @include media("xxxl") {
      padding: 36px 52px 32px;
    }

    @include media("fhd") {
      padding: 40px 53px 36px;
    }

    @include media("qhd") {
      padding: 44px 64px 40px;
    }

    &-left {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 6px;

      @include media("sm") {
        gap: 7px;
      }

      @include media("md") {
        gap: 8px;
      }

      @include media("lg") {
        gap: 9px;
      }

      @include media("xl") {
        gap: 10px;
      }

      @include media("xxxl") {
        gap: 11px;
      }

      @include media("fhd") {
        gap: 12px;
      }
    }
  }

  &__plans {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;

    @include media("md") {
      padding-top: 28px;
      padding-bottom: 28px;
      gap: 20px;
    }

    @include media("xl") {
      padding-top: 32px;
      padding-bottom: 32px;
      gap: 32px;
    }

    &-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: stretch;
      gap: 16px;
    }
  }

  &__head {
    font-size: 14px;
    font-weight: 600;
    color: map-get($map: $colors, $key: "text-primary");

    @include media("xl") {
      font-size: 16px;
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr;

    @include media("md") {
      grid-template-columns: 3fr 1fr;
    }

    @include media("lg") {
      min-height: 114px;
    }
  }
}
