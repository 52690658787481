@import "styles/variables";

.listable-table {
  display: flex;
  flex-direction: column;

  &__header {
    display: none;
    align-items: center;
    gap: 12px;
    padding-left: 16px;
    padding-right: 16px;

    @include media("md") {
      gap: 6px;
      padding-left: 20px;
      padding-right: 20px;
    }

    @include media("lg") {
      gap: 8px;
      padding-left: 24px;
      padding-right: 24px;

      &--action {
        padding-right: 0 !important;
      }
    }

    @include media("xl") {
      gap: 12px;
      display: grid;
      padding-left: 32px;
      padding-right: 32px;
    }

    @include media("xxl") {
      gap: 16px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @include media("xxxl") {
      padding-left: 48px;
      padding-right: 48px;
    }

    @include media("fhd") {
      gap: 20px;
      padding-left: 53px;
      padding-right: 53px;
    }

    @include media("qhd") {
      gap: 20px;
      padding-left: 64px;
      padding-right: 64px;
    }

    &-cell {
      color: transparency(map-get($colors, "text-primary"), 60);
      font-weight: 500;
      line-height: 1;
      padding: 12px 0;
      text-align: left;
      font-size: 10px;

      @include media("sm") {
        font-size: 11px;
      }

      @include media("md") {
        font-size: 12px;
      }

      @include media("lg") {
        padding: 16px 0;
        font-size: 13px;
      }

      @include media("xl") {
        font-size: 14px;
      }

      @include media("xxl") {
        font-size: 16px;
      }

      @include media("xxxl") {
        font-size: 17px;
      }

      @include media("qhd") {
        padding: 20px 0;
        font-size: 18px;
      }
    }
  }

  &__body {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    border-top: 1px solid map-get($colors, "border");
  }

  &__row {
    display: grid;
    align-items: center;
    padding: 16px;
    gap: 12px;
    border-bottom: 1px solid map-get($colors, "border");

    @include media("md") {
      gap: 6px;
      padding: 0 20px;
    }

    @include media("lg") {
      gap: 8px;
      display: grid;
      padding: 0 24px;

      &--action {
        padding-right: 0 !important;
      }
    }

    @include media("xl") {
      gap: 12px;
      padding: 0 32px;
    }

    @include media("xxl") {
      gap: 16px;
      padding: 0 40px;
    }

    @include media("xxxl") {
      padding: 0 48px;
    }

    @include media("fhd") {
      gap: 20px;
      padding: 0 53px;
    }

    @include media("qhd") {
      gap: 20px;
      padding: 0 64px;
    }

    &-group {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      border-bottom: 1px solid map-get($colors, "border");
    }

    &-group > & {
      border-bottom: none;
    }
  }

  &__extra {
    &-wrapper {
      padding-bottom: 16px;
      padding-left: 16px;
      padding-right: 16px;

      @include media("md") {
        padding-left: 20px;
        padding-right: 20px;
      }

      @include media("lg") {
        padding-left: 24px;
        padding-right: 24px;
      }

      @include media("xl") {
        padding-bottom: 24px;
        padding-left: 32px;
        padding-right: 32px;
      }

      @include media("xxl") {
        padding-left: 40px;
        padding-right: 40px;
      }

      @include media("xxxl") {
        padding-left: 48px;
        padding-right: 48px;
      }

      @include media("fhd") {
        padding-left: 53px;
        padding-right: 53px;
      }

      @include media("qhd") {
        padding-left: 64px;
        padding-right: 64px;
      }
    }

    &-row {
      background-color: map-get($colors, "bg-secondary");
      display: grid;
      align-items: stretch;
    }

    &-cell {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 12px 16px;
      gap: 4px;

      &:not(:last-child) {
        border-bottom: 1px solid
          transparency(map-get($colors, "text-primary"), 90);
      }

      @include media("md") {
        padding: 14px 20px;
      }

      @include media("xl") {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 16px 24px;

        &:not(:last-child) {
          border-bottom: none;
          border-right: 1px solid
            transparency(map-get($colors, "text-primary"), 90);
        }
      }

      @include media("xxl") {
        padding: 20px 30px;
      }

      @include media("xxxl") {
        padding: 24px 34px;
      }

      @include media("qhd") {
        padding: 28px 40px;
      }
    }
  }

  &__extra-row &__label {
    font-weight: 500;
  }

  &__cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;

    @include media("xl") {
      padding: 16px 0;

      .listable-table__label {
        display: none;
      }
    }

    @include media("xxl") {
      padding: 20px 0;
    }

    @include media("xxxl") {
      padding: 24px 0;
    }

    @include media("fhd") {
      padding: 26px 0;
    }

    @include media("qhd") {
      padding: 28px 0;
    }

    &--action {
      padding: 0 !important;
      align-self: stretch;
    }
  }

  &__cell--action > &__value {
    flex-grow: 1;
    align-self: stretch;
  }

  &__label {
    font-weight: 600;
    font-size: 10px;
    color: transparency(map-get($colors, "text-primary"), 40);
    line-height: 1.2;

    @include media("md") {
      font-size: 11px;
    }

    @include media("lg") {
      font-size: 12px;
    }

    @include media("xl") {
      font-size: 13px;
    }

    @include media("xxl") {
      font-size: 14px;
    }

    @include media("xxxl") {
      font-size: 15px;
    }

    @include media("fhd") {
      font-size: 16px;
    }
  }

  &__value {
    color: map-get($colors, "text-primary");
    font-weight: 600;
    line-height: 1.2;
    display: flex;
    align-items: stretch;
  }
}
