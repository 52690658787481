@import "styles/_variables.scss";

.withdraw-card {
  border-bottom: 1px solid map-get($colors, "border");
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  @include media("lg") {
    border-right: 1px solid map-get($colors, "border");
  }

  &__info {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 24px;
    right: 24px;

    @include media("lg") {
      top: 32px;
      right: 32px;
    }

    @include media("xxl") {
      width: 24px;
      height: 24px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: map-get($colors, "text-primary");
    padding: 20px 16px 32px;
    gap: 6px;

    @include media("md") {
      padding: 24px 16px 36px;
    }

    @include media("lg") {
      gap: 8px;
      padding: 28px 16px 40px;
    }

    @include media("xxl") {
      gap: 8px;
      padding: 32px 16px 44px;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr;
    align-items: stretch;
    gap: 16px;

    @include media("md") {
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
      gap: 8px;
    }
  }

  &__amount {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 4px;
    flex-grow: 1;
    padding-left: 20px;
    color: map-get($colors, "text-primary");

    @include media("md") {
      padding-left: 24px;
    }

    @include media("lg") {
      padding-left: 28px;
    }

    @include media("xxl") {
      padding-left: 32px;
    }

    &-value {
      font-weight: 600;
      font-size: 16px;
      display: flex;
      line-height: 1;
      align-items: flex-end;
      gap: 4px;

      @include media("md") {
        font-size: 18px;
      }

      @include media("lg") {
        font-size: 20px;
      }

      @include media("xl") {
        font-size: 22px;
      }

      @include media("xxl") {
        font-size: 24px;
      }

      span {
        opacity: 0.4;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.4;

        @include media("md") {
          font-size: 13px;
        }

        @include media("xl") {
          font-size: 14px;
        }
      }
    }
  }

  &__image {
    margin-bottom: 4px;
    width: 36px;
    height: 36px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @include media("md") {
      width: 40px;
      height: 40px;
    }

    @include media("xl") {
      width: 48px;
      height: 48px;
    }
  }

  &__value {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    text-align: center;

    @include media("md") {
      font-size: 24px;
    }

    @include media("xl") {
      font-size: 28px;
    }
  }

  &__address {
    opacity: 0.4;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    text-align: center;

    @include media("md") {
      font-size: 13px;
    }

    @include media("xl") {
      font-size: 14px;
    }
  }

  &__label {
    opacity: 0.4;
    font-size: 12px;
    font-weight: 600;

    @include media("md") {
      font-size: 13px;
    }

    @include media("xl") {
      font-size: 14px;
    }
  }
}
