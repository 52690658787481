@import "styles/_variables.scss";

.main-header {
  height: 56px;
  padding-right: 0;
  border-bottom: 1.5px solid map-get($colors, "border");
  background-color: map-get($colors, "bg");
  display: grid;
  grid-template-columns: 1fr 128px;
  position: fixed;
  align-items: stretch;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  @include media("sm") {
    height: 64px;
  }

  @include media("md") {
    height: 68px;
  }

  @include media("lg") {
    grid-template-columns: 168px 1fr 168px;
    height: 72px;
  }

  @include media("xl") {
    grid-template-columns: 190px 1fr 190px;
    height: 84px;
  }

  @include media("xxl") {
    height: 92px;
  }

  @include media("xxxl") {
    grid-template-columns: 220px 1fr 220px;
    height: 96px;
  }

  @include media("fhd") {
    grid-template-columns: 247px 1fr 247px;
    height: 114px;
  }

  @include media("qhd") {
    grid-template-columns: 275px 1fr 275px;
    height: 120px;
  }

  &__logo-section {
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    color: map-get($colors, "text-primary");
    border-right: 1.5px solid map-get($colors, "border");

    @include media("md") {
      padding: 0 16px 0 24px;
    }

    @include media("lg") {
      padding: 0 16px 0 32px;
    }
  }

  &__nav {
    display: none;

    @include media("lg") {
      display: block;
    }
  }
}
