@import "styles/variables";

.wallet-address {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include media("lg") {
    gap: 16px;
  }

  &__header {
    @include center;
    justify-content: space-between;
    &--title {
      color: map-get($map: $colors, $key: "text-primary");
      font-size: 16px;
      font-weight: 600;
    }

    &--edit {
      color: map-get($map: $colors, $key: "primary");
      font-size: 14px;
      font-weight: 600;
      @include center;
      gap: 8px;
      svg {
        path {
          stroke: map-get($map: $colors, $key: "primary");
        }
      }
    }
  }
  &__input {
    min-width: 360px;
    width: 100%;
    height: 38px;
    background-color: map-get($map: $colors, $key: "bg-secondary");
    border-radius: 4px;
    overflow: hidden;

    input {
      padding: 0 10px;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: unset;
      color: transparency(map-get($map: $colors, $key: "text-primary"), 40);
      font-size: 14px;
      font-weight: 600;
    }
  }
}
