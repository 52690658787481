@import "styles/variables";

.status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;
  }

  &.approved {
    color: map-get($map: $colors, $key: "primary");

    svg {
      path {
        stroke: map-get($map: $colors, $key: "primary");
      }
    }
  }

  &.rejected {
    color: map-get($map: $colors, $key: "secondary");

    svg {
      path {
        stroke: map-get($map: $colors, $key: "secondary");
      }
    }
  }

  &.pending {
    color: map-get($map: $colors, $key: "warning");

    svg {
      path {
        stroke: map-get($map: $colors, $key: "warning");
      }
    }
  }
}
