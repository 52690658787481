@import "styles/_variables.scss";

.kyc {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  padding: 16px;

  @include media("md") {
    padding: 20px 16px;
  }

  @include media("lg") {
    padding: 24px 20px;
    gap: 24px;
  }

  @include media("xxl") {
    padding: 28px 24px;
    gap: 32px;
  }

  @include media("xxxl") {
    padding: 32px;
  }

  @include media("fhd") {
    padding: 32px 40px;
  }

  &__title {
    color: map-get($map: $colors, $key: "text-primary");
    font-size: 16px;
    font-weight: 600;

    @include media("xl") {
      font-size: 18px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 0 10%;
    text-align: center;
  }

  &__image {
    width: 150px;
    height: 150px;
  }

  &__status {
    font-weight: 700;
    line-height: 1;
    font-size: 16px;

    @include media("xl") {
      font-size: 20px;
    }

    &--approved {
      color: map-get($map: $colors, $key: "primary");
    }

    &--rejected {
      color: map-get($map: $colors, $key: "secondary");
    }

    &--pending,
    &--reviewing {
      color: map-get($map: $colors, $key: "warning");
    }
  }

  &__description {
    font-size: 12px;
    color: map-get($map: $colors, $key: "text-secondary");
    text-align: center;

    @include media("xl") {
      font-size: 14px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    width: 100%;

    @include media("xl") {
      max-width: 230px;
    }
  }
}
