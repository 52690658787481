@import "styles/variables";

.table-wrapper {
  position: relative;

  table {
    width: 100%;
    text-align: left;

    thead {
      th {
        color: transparency(map-get($colors, "text-primary"), 60);
        border-top: 1px solid map-get($colors, "border");
        border-bottom: 1px solid map-get($colors, "border");
        font-weight: 500;
        line-height: 1;
        padding: 12px 0 12px 12px;
        font-size: 10px;

        &:first-child {
          padding-left: 16px;

          @include media("md") {
            padding-left: 20px;
          }

          @include media("lg") {
            padding: 16px 0 24px 16px;
          }

          @include media("xl") {
            padding-left: 32px;
          }

          @include media("xxl") {
            padding-left: 40px;
          }

          @include media("xxxl") {
            padding-left: 48px;
          }

          @include media("fhd") {
            padding-left: 53px;
          }

          @include media("qhd") {
            padding: 20px 0 64px 20px;
          }
        }

        @include media("sm") {
          font-size: 11px;
        }

        @include media("md") {
          font-size: 12px;
          padding-left: 6px;
        }

        @include media("lg") {
          font-size: 13px;
          padding-left: 8px;
        }

        @include media("xl") {
          font-size: 14px;
          padding-left: 12px;
        }

        @include media("xxl") {
          font-size: 16px;
          padding-left: 16px;
        }

        @include media("xxxl") {
          font-size: 17px;
        }

        @include media("qhd") {
          font-size: 18px;
          padding-left: 20px;
        }
      }
    }

    tbody {
      td {
        border-bottom: 1px solid map-get($colors, "border");
        padding: 16px 12px;
        font-weight: 500;
        font-size: 12px;

        &:first-child {
          padding-left: 16px;

          @include media("md") {
            padding-left: 20px;
          }

          @include media("lg") {
            padding-left: 24px;
          }

          @include media("xl") {
            padding-left: 32px;
          }

          @include media("xxl") {
            padding-left: 40px;
          }

          @include media("xxxl") {
            padding-left: 48px;
          }

          @include media("fhd") {
            padding-left: 53px;
          }

          @include media("qhd") {
            padding-left: 64px;
          }
        }

        @include media("md") {
          padding-left: 6px;
        }

        @include media("lg") {
          font-size: 13px;
          padding-left: 8px;
        }

        @include media("xl") {
          font-size: 14px;
          padding: 16px 12px;
        }

        @include media("xxl") {
          font-size: 16px;
          padding: 20px 16px;
        }

        @include media("xxxl") {
          font-size: 17px;
        }

        @include media("qhd") {
          font-size: 18px;
          padding: 28px 20px;
        }
      }
    }
  }

  .pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 24px;
    border-bottom: 1px solid map-get($colors, "border");

    .size-selector {
      display: flex;
      min-height: 84px;
      align-items: center;
      justify-content: start;
      padding: 16px;
      border-right: 1px solid map-get($colors, "border");

      @include media("md") {
        padding-left: 20px;
        padding-right: 12px;
      }

      @include media("lg") {
        padding-left: 24px;
        padding-right: 12px;
      }

      @include media("xl") {
        padding-left: 32px;
        padding-right: 16px;
      }

      @include media("xxl") {
        padding-left: 40px;
        padding-right: 20px;
      }

      @include media("xxxl") {
        padding-left: 48px;
        padding-right: 20px;
      }

      @include media("fhd") {
        padding-left: 53px;
        padding-right: 26px;
      }

      @include media("qhd") {
        padding: 20px 32px 64px 20px;
      }

      p {
        color: transparency(map-get($colors, "text-primary"), 60);
      }
    }

    .actions {
      @include center;
      padding-right: 12px;

      @include media("xxxl") {
        padding-right: 32px;
      }

      span {
        border-left: 1px solid map-get($colors, "border");
        color: transparency(map-get($colors, "text-primary"), 60);
      }

      span,
      button {
        padding: 16px;
        min-height: 84px;
        border-right: 1px solid map-get($colors, "border");

        @include media("md") {
          padding-left: 20px;
          padding-right: 20px;
        }

        @include media("lg") {
          padding-left: 24px;
          padding-right: 24px;
        }

        @include media("xl") {
          padding-left: 32px;
          padding-right: 32px;
        }

        @include media("xxl") {
          padding-left: 40px;
          padding-right: 40px;
        }

        @include media("xxxl") {
          padding-left: 48px;
          padding-right: 48px;
        }

        @include media("fhd") {
          padding-left: 53px;
          padding-right: 53px;
        }

        @include media("qhd") {
          padding: 20px 64px 64px 20px;
        }
      }
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
    }

    button {
      font-size: 1rem;
      color: inherit;
      font-weight: 500;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    select {
      font-size: 1rem;
      border: 1px solid map-get($colors, "border");
      background: transparent;
      padding: 12px 18px;
      border-radius: 8px;
      color: inherit;
      font-weight: 500;

      &:focus {
        outline: unset;
      }
    }
  }

  .loading-wrapper {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: transparency(map-get($colors, "bg"), 3);
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 1rem;
      font-weight: 500;
    }
  }
}
