@import "styles/variables";

.link {
  @include center;
  gap: 4px;
  color: map-get($colors, "primary");
  justify-content: left;
  text-wrap: nowrap;
  svg {
    path {
      stroke: map-get($colors, "primary");
    }
  }
}
