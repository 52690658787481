//variables
$colors: (
  bg: #0b0c0d,
  bg-secondary: #1c1b1f,
  primary: #68f029,
  secondary: #ff5039,
  text-primary: #ffffff,
  text-secondary: #6c6c70,
  text-tertiary: #5b636f,
  warning: #f0c429,
  success: #00ffe0,
  border: #171819,
);

$breakpoints: (
  xs: 370px,
  sm: 576px,
  md: 852px,
  lg: 968px,
  xl: 1080px,
  xxl: 1200px,
  xxxl: 1320px,
  fhd: 1560px,
  qhd: 1921px,
  uhd: 2561px,
);

// mixins
@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin full {
  width: 100%;
  height: 100%;
}

@mixin media($breakpoint) {
  @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

// Functions
@function transparency($color, $percentage) {
  $red: red($color);
  $green: green($color);
  $blue: blue($color);
  $alpha: alpha($color);

  $new-alpha: $alpha - ($percentage / 100);
  $new-alpha: max(0, min(1, $new-alpha)); // Clamp alpha between 0 and 1

  @return rgba($red, $green, $blue, $new-alpha);
}
