@import "styles/_variables.scss";

.input {
  &__container {
    position: relative;
  }

  &__label {
    font-weight: 500;
    color: map-get($map: $colors, $key: "text-primary");
    margin-bottom: 8px;
    padding-left: 14px;
    font-size: 10px;

    @include media("sm") {
      font-size: 11px;
    }

    @include media("md") {
      font-size: 12px;
    }

    @include media("lg") {
      font-size: 13px;
      margin-bottom: 12px;
    }

    @include media("xl") {
      font-size: 14px;
    }

    @include media("xxl") {
      font-size: 15px;
    }
  }

  &__box {
    padding: 0 12px;
    height: 52px;
    border: 1.5px solid map-get($map: $colors, $key: "border");
    display: flex;
    color: map-get($map: $colors, $key: "text-primary");
    border-radius: 8px;
    @include center;
    justify-content: space-between;
    gap: 12px;

    @include media("md") {
      height: 56px;
      padding: 0 16px;
    }

    @include media("lg") {
      height: 60px;
      padding: 0 20px;
      gap: 16px;
    }

    @include media("xl") {
      height: 64px;
      padding: 0 22px;
    }

    @include media("xxl") {
      height: 72px;
      padding: 0 24px;
    }
  }

  &__boxWithError {
    border-color: map-get($map: $colors, $key: "secondary");
  }

  &__input {
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    background-color: transparent !important;
    color: map-get($map: $colors, $key: "text-primary") !important;
    font-size: 12px;

    &--password {
      padding-right: 40px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    @include media("sm") {
      font-size: 13px;
    }

    @include media("md") {
      font-size: 14px;
    }

    @include media("lg") {
      font-size: 15px;
    }

    @include media("xl") {
      font-size: 16px;
    }

    &::placeholder {
      color: transparency(map-get($colors, "text-primary"), 60);
      font-weight: 500;
    }
  }

  &__button {
    position: absolute;
    right: 20px;
    color: map-get($map: $colors, $key: "secondary");
    font-size: 10px;
    text-transform: lowercase;

    @include media("sm") {
      font-size: 11px;
    }

    @include media("md") {
      font-size: 12px;
    }

    @include media("lg") {
      font-size: 13px;
    }

    @include media("xl") {
      font-size: 14px;
    }
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
    margin-top: 8px;
    padding-left: 14px;

    @include media("lg") {
      margin-top: 12px;
    }

    span {
      font-weight: 500;
      font-size: 10px;
      color: map-get($map: $colors, $key: "secondary");

      @include media("sm") {
        font-size: 11px;
      }

      @include media("md") {
        font-size: 12px;
      }

      @include media("lg") {
        font-size: 13px;
      }

      @include media("xl") {
        font-size: 14px;
      }

      @include media("xxl") {
        font-size: 15px;
      }
    }

    svg {
      width: 18px;
      height: 18px;
      color: map-get($map: $colors, $key: "secondary");
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    max-height: 24px;

    & > svg {
      @include full;
    }
  }
}
