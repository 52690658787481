@import "styles/variables";

.action-button {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;

  border-left: 1px solid #171819;
  @include full;
  color: map-get($map: $colors, $key: "text-secondary");
  svg {
    margin-top: 6px;
    path {
      stroke: map-get($map: $colors, $key: "text-secondary");
    }
  }
  &.white {
    &.active {
      color: map-get($map: $colors, $key: "text-primary");
      background-color: #1c1b1f;
      svg {
        path {
          stroke: map-get($map: $colors, $key: "text-primary");
        }
      }
    }
    &:hover {
      color: map-get($map: $colors, $key: "text-primary");
      background-color: #1c1b1f;
      svg {
        path {
          stroke: map-get($map: $colors, $key: "text-primary");
        }
      }
    }
  }
  &.green {
    &.active {
      color: map-get($map: $colors, $key: "primary");
      background-color: #1c1b1f;
      svg {
        path {
          stroke: map-get($map: $colors, $key: "primary");
        }
      }
    }
    &:hover {
      color: map-get($map: $colors, $key: "primary");
      background-color: #1c1b1f;
      svg {
        path {
          stroke: map-get($map: $colors, $key: "primary");
        }
      }
    }
  }
  &.red {
    &.active {
      color: map-get($map: $colors, $key: "secondary");
      background-color: #1c1b1f;
      svg {
        path {
          stroke: map-get($map: $colors, $key: "secondary");
        }
      }
    }
    &:hover {
      color: map-get($map: $colors, $key: "secondary");
      background-color: #1c1b1f;
      svg {
        path {
          stroke: map-get($map: $colors, $key: "secondary");
        }
      }
    }
  }
  &.orange {
    &.active {
      color: map-get($map: $colors, $key: "warning");
      background-color: #1c1b1f;
      svg {
        path {
          stroke: map-get($map: $colors, $key: "warning");
        }
      }
    }
    &:hover {
      color: map-get($map: $colors, $key: "warning");
      background-color: #1c1b1f;
      svg {
        path {
          stroke: map-get($map: $colors, $key: "warning");
        }
      }
    }
  }
}
