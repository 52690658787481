@import "styles/variables";
.footer {
  text-align: center;
  .social-media {
    @include center;
    gap: 26px;
    padding-bottom: 26px;
    button {
      &:hover {
        svg {
          g {
            opacity: 1;
          }
        }
      }
    }
    p {
      font-size: 16px;
    }
  }
}
