.learn-card {
  border-right: 2px solid #171819;
  width: 100%;

  .title {
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 400px;
    display: block;
  }
  .image {
    height: 210px;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
