@import "styles/_variables.scss";

.aml {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @include media("xl") {
    gap: 12px;
  }

  &__state {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    color: map-get($map: $colors, $key: "text-primary");

    @include media("xxl") {
      font-size: 16px;
    }

    &--approved {
      color: map-get($map: $colors, $key: "primary");
    }

    &--rejected {
      color: map-get($map: $colors, $key: "secondary");
    }

    &--pending,
    &--reviewing {
      color: map-get($map: $colors, $key: "warning");
    }
  }

  &__button {
    height: 44px;
    width: 100%;

    @include media("xl") {
      height: 50px;
    }
  }
}
