@import "styles/variables";

.title {
  width: fit-content;
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  color: map-get($map: $colors, $key: "text-primary");
  position: relative;

  @include media("sm") {
    font-size: 20px;
  }

  @include media("md") {
    font-size: 22px;
  }

  @include media("lg") {
    font-size: 24px;
  }

  @include media("xl") {
    font-size: 26px;
  }

  @include media("xxl") {
    font-size: 28px;
  }

  @include media("xxxl") {
    font-size: 32px;
  }

  @include media("fhd") {
    font-size: 34px;
  }

  @include media("qhd") {
    font-size: 36px;
  }

  &__badge {
    font-size: 7px;
    font-weight: 600;
    color: transparency(map-get($map: $colors, $key: "text-primary"), 60);
    position: absolute;
    left: calc(100% + 7px);
    top: 2px;

    @include media("sm") {
      font-size: 8px;
    }

    @include media("md") {
      font-size: 9px;
    }

    @include media("lg") {
      font-size: 10px;
    }

    @include media("xl") {
      font-size: 11px;
    }

    @include media("xxl") {
      font-size: 12px;
    }

    @include media("xxxl") {
      font-size: 13px;
    }

    @include media("qhd") {
      font-size: 14px;
    }
  }
}
