@import "styles/variables.scss";

.wallet-management-row {
  grid-template-columns: 1fr;

  @include media("sm") {
    grid-template-columns: repeat(2, 1fr);

    & .hidden-row {
      display: none;
    }
  }

  @include media("lg") {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media("xl") {
    grid-template-columns: 4fr repeat(2, 2fr) 3fr;

    & .hidden-row {
      display: block;
    }
  }

  &__actions {
    display: flex;
    align-items: stretch;
    flex-grow: 1;

    & > button {
      flex-grow: 1;
    }
  }
}
