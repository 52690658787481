@import "styles/_variables.scss";

.token-input {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;

  @include media("lg") {
    gap: 12px;
  }

  &__body {
    border: 1.5px solid map-get($colors, "border");
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;

    @include media("lg") {
      padding: 20px;
    }

    @include media("xl") {
      padding: 24px;
    }
  }

  &__bodyWithError {
    border-color: map-get($map: $colors, $key: "secondary");
  }

  &__info {
    display: flex;
    align-items: center;
    border-right: 2px solid map-get($colors, "border");
    padding-right: 16px;
    gap: 8px;
    min-height: 28px;

    @include media("lg") {
      padding-right: 20px;
      gap: 12px;
    }

    @include media("xl") {
      padding-right: 24px;
    }
  }

  &__img {
    width: 20px;
    height: 20px;
    border-radius: 500px;

    @include media("lg") {
      width: 24px;
      height: 24px;
    }

    @include media("xxl") {
      width: 28px;
      height: 28px;
    }
  }

  &__name {
    color: map-get($colors, "text-primary");
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    @include media("xl") {
      font-size: 14px;
    }
  }

  &__arrow {
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media("lg") {
      width: 12px;
      height: 12px;
    }

    @include media("xxl") {
      width: 14px;
      height: 14px;
    }
  }

  &__input {
    border: none;
    background-color: transparent;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    padding: 0 16px;
    flex-grow: 1;
    color: map-get($colors, "text-primary");

    &::placeholder {
      color: transparency(map-get($colors, "text-primary"), 60);
    }

    &:focus,
    &:active {
      border: none;
      outline: none;
    }

    @include media("lg") {
      padding-right: 20px;
      gap: 12px;
    }

    @include media("xl") {
      padding-right: 24px;
      font-size: 16px;
    }
  }

  &__max {
    color: map-get($colors, "primary");
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    @include media("xl") {
      font-size: 14px;
    }
  }

  &__balance {
    padding: 0 16px;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    gap: 4px;

    @include media("lg") {
      padding: 0 20px;
    }

    @include media("xl") {
      padding: 0 24px;
    }
  }

  &__balance.error-message {
    color: map-get($map: $colors, $key: "secondary");
    display: flex;
    align-items: center;
    margin-top: 4px;

    @include media("lg") {
      padding: 0 20px;
    }

    @include media("xl") {
      padding: 0 24px;
    }
  }

  &__label {
    color: map-get($colors, "text-secondary");
  }

  &__value {
    color: map-get($colors, "text-primary");
  }
}

.lock {
  border-top: 1px solid map-get($colors, "border");
  border-bottom: 1px solid map-get($colors, "border");
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__calculator {
    border-bottom: 1px solid map-get($colors, "border");
    display: grid;
    grid-template-columns: 1fr;

    @include media("lg") {
      grid-template-columns: 1fr 1fr;
    }

    @include media("xl") {
      grid-template-columns: 3fr 2fr;
    }
  }

  &__entry {
    border-bottom: 1px solid map-get($colors, "border");
    padding: 14px 16px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;

    @include media("md") {
      padding: 16px 16px 14px 20px;
      gap: 16px;
    }

    @include media("lg") {
      border-bottom: none;
      border-right: 1px solid map-get($colors, "border");
      padding: 20px 18px 18px 24px;
      gap: 24px;
    }

    @include media("xl") {
      padding: 24px 24px 20px 32px;
      gap: 28px;
    }

    @include media("xxl") {
      padding: 32px 32px 30px 44px;
      gap: 32px;
    }

    @include media("xxxl") {
      padding: 36px 36px 32px 52px;
    }

    @include media("fhd") {
      padding: 40px 40px 36px 53px;
    }

    @include media("qhd") {
      padding: 44px 44px 40px 64px;
    }
  }

  &__calculation {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-row {
      flex-grow: 1;
      flex-basis: 50%;
      display: flex;
      flex-direction: column;

      @include media("lg") {
        flex-direction: row;
      }

      &:not(:last-child) {
        border-bottom: 1px solid map-get($colors, "border");
      }

      &--highlighted {
        background-color: map-get($colors, "bg-secondary");
      }
    }

    &-item {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 16px;

      &-inner {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid map-get($colors, "border");

        @include media("lg") {
          border-bottom: none;
          border-right: 1px solid map-get($colors, "border");
        }
      }

      @include media("lg") {
        padding: 0 24px;
      }
    }

    &-title {
      font-size: 14px;
      font-weight: 500;
      color: transparency(map-get($colors, "text-primary"), 60);
    }

    &-value {
      color: map-get($colors, "text-primary");
      font-size: 20px;
      font-weight: 600;

      @include media("lg") {
        font-size: 24px;
      }

      @include media("xxl") {
        font-size: 32px;
      }
    }

    &-unit {
      font-size: 14px;
      font-weight: 500;
      color: transparency(map-get($colors, "text-primary"), 60);
      margin-left: 8px;
    }
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr;

    @include media("lg") {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media("xxl") {
      grid-template-columns: repeat(4, 1fr);
    }

    &-item {
      padding: 16px;
      border-bottom: 1px solid map-get($colors, "border");
      border-right: 1px solid map-get($colors, "border");
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-inner {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
      }

      @include media("lg") {
        padding: 20px;
      }

      @include media("xl") {
        padding: 24px;
      }

      @include media("xxl") {
        padding: 32px;
      }
    }

    &-title {
      font-size: 14px;
      font-weight: 500;
      color: transparency(map-get($colors, "text-primary"), 60);
    }

    &-value {
      color: map-get($colors, "text-primary");
      font-size: 16px;
      font-weight: 600;
    }
  }
}
