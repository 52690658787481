@import "styles/_variables.scss";

.portfolio-modal {
  .content {
    padding: 36px;
  }
  .flex {
    @include center;
    gap: 8px;
    & > div {
      width: 100%;
    }
  }

  .button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 640px;
    height: 82px;
  }
  .input {
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}
.upload-logo {
  @include center;
  width: 100%;
  border-radius: 8px;
  border: 1px dashed rgba(255, 255, 255, 0.1);
  input {
    display: none;
  }
  label {
    cursor: pointer;
    width: 100%;
    border-radius: 2px;
    color: map-get($map: $colors, $key: "text-primary");
    @include center;
    flex-direction: column;
    padding: 24px;
    margin-bottom: 8px;
  }
  span {
    font-size: 12px;
  }
}
