@import "styles/variables";

.logo {
  &__img {
    width: 161px;
    height: 55px;
    padding: 16px 0;
  }

  &__name {
    font-weight: 700;
    line-height: 1;
    font-size: 12px;

    @include media("md") {
      font-size: 13px;
    }

    @include media("lg") {
      font-size: 14px;
    }
  }
}
