@import "styles/_variables.scss";

.investment-card {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  border-radius: 8px;
  border: 2px solid map-get($map: $colors, $key: "border");
  transition: all 0.3s ease;
  will-change: transform, border-color;
  cursor: pointer;
  flex-grow: 1;
  min-width: 220px;

  &:hover {
    transform: scale(1.02);
    border-color: map-get($map: $colors, $key: "primary");
  }

  &.active {
    border-color: map-get($map: $colors, $key: "primary");
  }

  &__head {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__selected {
    position: absolute;
    top: -10px;
    right: -8px;
    width: 34px;
    height: 34px;
    border-radius: 100%;
    background-color: map-get($map: $colors, $key: "bg");
    @include center;
    border: 2px solid map-get($map: $colors, $key: "primary");
    opacity: 0;
    transform: scale(0.7);
    transition: all 0.3s ease;
    will-change: transform, opacity;
  }

  &.active &__selected {
    opacity: 1;
    transform: scale(1);
  }

  &__id {
    color: map-get($map: $colors, $key: "text-primary");
    font-size: 32px;
    font-weight: 700;

    sup {
      font-weight: 600;
      font-size: 16px;
    }
  }

  &__apy {
    color: transparency(map-get($map: $colors, $key: "text-primary"), 60);
    font-size: 16px;
    font-weight: 600;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    button {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: map-get($map: $colors, $key: "bg-secondary");
      padding: 24px;
      max-height: 68px;
      font-weight: 500;
      font-size: 14px;
      transition: all 0.3s ease;
      will-change: background-color, color;
      color: transparency(map-get($map: $colors, $key: "text-primary"), 40);
    }
  }

  &:hover &__action button,
  &.active &__action button {
    background-color: map-get($map: $colors, $key: "primary");
    color: map-get($map: $colors, $key: "bg");
  }

  &__row {
    border-top: 1px solid map-get($map: $colors, $key: "border");
    padding: 12px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    @include media("lg") {
      padding: 24px 16px;
    }
  }

  &__label {
    max-width: 70px;
    color: transparency(map-get($map: $colors, $key: "text-primary"), 60);
    font-size: 12px;
    font-weight: 500;
  }

  &__value {
    text-align: right;
    color: map-get($map: $colors, $key: "text-primary");
    font-size: 16px;
    font-weight: 600;

    & > span {
      color: transparency(map-get($map: $colors, $key: "text-primary"), 60);
      padding-left: 4px;
      font-size: 12px;
    }
  }
}
