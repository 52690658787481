@import "styles/variables";

.tabs {
  padding: 8px;
  width: 100%;
  @include center;
  background-color: map-get($colors, "border");

  &__item {
    text-align: center;
    width: 100%;
    padding: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: transparency(map-get($colors, "text-primary"), 60);
    font-size: 12px;
    font-weight: 500;

    @include media("md") {
      font-size: 13px;
    }

    @include media("lg") {
      font-size: 14px;
    }

    &--active {
      color: map-get($colors, "text-primary");
      background-color: map-get($colors, "bg");
    }
  }
}
